body {
  color: #fff;
  background: #1e1f22;
  font-family: Arial, Helvetica, sans-serif;
}

.letters-row {
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.letter {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid gray;
  border-radius: 5px;
  width: 5rem;
  margin-left: .5rem;
  margin-right: .5rem;
  font-size: 4rem;
  font-weight: bold;
}

.letter:active {
  background: gray;
}

.word {
  justify-content: center;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
}

.button-holder {
  justify-content: center;
  width: 100%;
  display: flex;
}

.button-holder button {
  margin-left: 2px;
  margin-right: 2px;
}

.state-holder {
  justify-content: space-between;
  display: flex;
}

.time-holder {
  text-align: right;
}

.muted {
  color: gray;
}

.button-47 {
  box-sizing: border-box;
  fill: #fff;
  cursor: pointer;
  touch-action: manipulation;
  background: #1e1f22;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  font-family: sans-serif;
  font-size: 1rem;
  display: inline-flex;
}

/*# sourceMappingURL=index.49e71e90.css.map */
