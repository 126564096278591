
body {
    background: #1e1f22;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

.letters-row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.letter {
    font-size: 4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 5rem;
    text-align: center;
    border: 2px solid grey;
    border-radius: 5px;
    user-select: none;
}

.letter:active {
    background: grey;
}

.word {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
}

.button-holder {
    display: flex;
    justify-content: center;
    width: 100%;
}

.button-holder button {
    margin-left: 2px;
    margin-right: 2px;
}

.state-holder {
    display: flex;
    justify-content: space-between;
}

.time-holder {
    text-align: right;
}

.muted {
    color: grey;
}

.button-47 {
  align-items: center;
  background: #1e1f22;
  box-sizing: border-box;
  fill: white;
  display: inline-flex;
  font-family: sans-serif;
  font-size: 1rem;
  height: 4rem;
  width: 4rem;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  touch-action: manipulation;
}
